import { Cmp, Ctrl, log, state } from ":mods";
import { getProfileDetails } from "../apis";
import { _token_actions, actions, events } from "../store";
import { Show, createEffect } from "solid-js";
import { $token } from "../store";
import { ROUTES } from "../const";
import { logout } from "../apis";

export function TopNotifications(props: Ctrl.Nav.model.TopNavJSXItemProps) {
  return (
    <Ctrl.Nav.TopElement
      base={props.base}
      arrowMode="hidden"
      groupClass="flex flex-row items-center space-x-2"
      itemsClass="flex flex-col bg#p px-2 py-1 space-y-3 mt-6.5 !w-200px -translate-x-70%"
      items={<div class="capitalize">you have no notifications</div>}
    >
      <section class="relative flex flex-row items-center !w-full">
        <i class="icon-iconoir:bell w-24px h-24px" />
      </section>
    </Ctrl.Nav.TopElement>
  );
}
